import { defineStore } from 'pinia';
import {
  fetchCourse,
  fetchExercises,
  fetchCourses,
  updateLesson,
} from '/@/app/services/courses';
import { ISkill } from '/@/app/types/interfaces';

interface Course {
  id: string;
  caption: string;
  total_lessons: number;
  theme: string;
  lessons: any[];
  outline: any[];
  units: any[];
  disciplines: any[];
  gates: any[];
  grades: any[];
  loading: boolean;
}

export const useCourseStore = defineStore({
  id: 'course',
  state: () => ({
    courses: [] as Course[],
    course: {} as Course,
    skill: {} as ISkill,
    instructors: [] as any[],
    lessons: {} as { [lesson: string]: any },
    questions: [] as any[],
    videos: [] as any[],
    exercises: [] as any[],
    loading: false,
  }),
  getters: {
    lessonsOptions: (state: any) =>
      state.course.lessons
        ? state.course.lessons.map(({ lesson, id, title }) => ({
            title,
            value: id,
            number: lesson,
          }))
        : [],
  },
  actions: {
    fetchCourses(): Promise<void> {
      return new Promise(resolve => {
        const { onSuccess } = fetchCourses();
        onSuccess(({ data }) => {
          this.courses = data;
          resolve();
        });
      });
    },
    fetchExercises(course, lesson) {
      this.loading = true;
      const { onSuccess, onError } = fetchExercises(course, lesson);
      onSuccess(({ data }) => {
        this.exercises = data;
        this.loading = false;
      });
      onError(() => {
        this.loading = false;
      });
    },
    fetchCourse(id) {
      const { onSuccess } = fetchCourse(id);
      onSuccess(({ data }) => {
        this.course = data;
      });
      return { onSuccess };
    },

    updateLesson(lesson) {
      return new Promise(resolve => {
        if (lesson.id) {
          let { onSuccess } = updateLesson(lesson.course, lesson.id, lesson);
          onSuccess(data => {
            resolve(data);
          });
        }
        resolve({});
      });
    },
  },
});
